import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Features from "../components/Features";
import Pricing from "../components/Pricing";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import InBlog from "../components/InBlog";
import ChatButton from "../components/ChatButton";
import { Helmet } from 'react-helmet-async'; // Correct import

function Home() {
  return (
    <>
      {/* Helmet component to wrap all meta tags and title */}
      <Helmet>
        <title>Seotic - Powerful SEO Tools for Small Businesses</title>
        <meta
          name="description"
          content="Seotic provides easy to use SEO tools for small businesses to enhance online visibility, drive traffic, and grow your brand. Get started with Seotic today!"
        />
        <meta name="keywords" content="SEO, SEO tools, small business, digital marketing, online visibility, Seotic" />
        <link rel="icon" href="https://www.seotic.co/favicon.ico" />

        {/* Open Graph Meta Tags */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.seotic.co" />
        <meta property="og:title" content="Seotic - SEO Tools for Small Businesses" />
        <meta
          property="og:description"
          content="Optimize your website's SEO with Seotic's powerful, user friendly tools designed for small businesses. Start improving your search rankings today!"
        />
        <meta property="og:image" content="https://www.seotic.co/og-image.jpg" />

        {/* Twitter Card Meta Tags */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.seotic.co" />
        <meta property="twitter:title" content="Seotic - SEO Tools for Small Businesses" />
        <meta
          property="twitter:description"
          content="Optimize your website's SEO with Seotic's powerful, user friendly tools designed for small businesses. Start improving your search rankings today!"
        />
        <meta property="twitter:image" content="https://www.seotic.co/og-image.jpg" />
      </Helmet>

      {/* Main Content */}
      <div className="">
        <Navbar />
        <Hero />
        <Features />
        <Pricing />
        <InBlog />
        <Faq />
        <div className="container mx-auto my-10">
          <ChatButton />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Home;
