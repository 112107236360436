// src/components/NotFound.js
import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async'; // Correct import

const NotFound = () => {
  return (
	  <>
	   {/* Helmet component to wrap all meta tags and title */}
      <Helmet>
        <title>Seotic - 404</title>
        <meta
          name="description"
          content="Seotic provides easy to use SEO tools for small businesses to enhance online visibility, drive traffic, and grow your brand. Get started with Seotic today!"
        />
        <meta name="keywords" content="SEO, SEO tools, small business, digital marketing, online visibility, Seotic" />
        <link rel="icon" href="https://www.seotic.co/favicon.ico" />

        {/* Open Graph Meta Tags */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.seotic.co" />
        <meta property="og:title" content="Seotic - SEO Tools for Small Businesses" />
        <meta
          property="og:description"
          content="Optimize your website's SEO with Seotic's powerful, user friendly tools designed for small businesses. Start improving your search rankings today!"
        />
        <meta property="og:image" content="https://www.seotic.co/og-image.jpg" />

        {/* Twitter Card Meta Tags */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.seotic.co" />
        <meta property="twitter:title" content="Seotic - SEO Tools for Small Businesses" />
        <meta
          property="twitter:description"
          content="Optimize your website's SEO with Seotic's powerful, user friendly tools designed for small businesses. Start improving your search rankings today!"
        />
        <meta property="twitter:image" content="https://www.seotic.co/og-image.jpg" />
      </Helmet>

    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 text-center">
      <h1 className="text-6xl font-bold text-yellow-500 mb-4">404</h1>
      <h2 className="text-2xl font-semibold text-gray-800 mb-2">
        Oops! Page Not Found
      </h2>
      <p className="text-gray-600 mb-8">
        The page you're looking for doesn’t exist or has been moved.
      </p>
      <Link
        to="/"
        className="px-6 py-3 bg-yellow-500 text-white font-semibold rounded-lg shadow-md hover:bg-yellow-600 transition duration-300"
      >
        Go Back Home
      </Link>
    </div>
    </>
  );
};

export default NotFound;
