import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ReactMarkdown from "react-markdown";
import LikeButton from "../components/LikeButton";

function SupportPost() {
  const { parama } = useParams(); // Get parama from URL
  const [post, setPost] = useState(null); // State to hold post data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  
  const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleString('en-GB', { month: 'short' });
  const year = date.getFullYear().toString().slice(-2);
  return `${day} ${month}, ${year}`; // Adds comma between month and year
};

  useEffect(() => {
    // Fetch post data from API
    const timestamp = new Date().getTime();
    fetch(`https://www.pixelliongroup.com/seotic/getSupport.php?parama=${parama}&t=${timestamp}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch post data");
        }
        return response.json();
      })
      .then((data) => {
        const matchedPost = data.find((p) => p.parama === parama); // Match by parama
        if (matchedPost) {
          setPost(matchedPost); // Set the post data
        } else {
          setError("Post not found");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching post:", err);
        setError("Error fetching post");
        setLoading(false);
      });
  }, [parama]);

  // Loading and error states
  if (loading) {
    return (
    <>
    <Navbar />
    <div className="bg-slate-100 h-full">
        <div className="container mx-auto py-12">
    <div className="text-center"><i class='bx bx-circle bx-burst bx-rotate-270 bx-fw'></i>Loading...</div>
    </div>
    </div>
    <Footer />
    </>
    );
  }

  if (error) return <div>{error}</div>;
  if (!post) return null;

  // Split tags by comma and trim whitespace
  const tags = post.tags.split(",").map((tag) => tag.trim());

  // Calculate reading time
  const wordsPerMinute = 200; // Average reading speed
  const wordCount = post.content.split(" ").length; // Count words
  const readingTime = Math.ceil(wordCount / wordsPerMinute); // Calculate minutes

  return (
    <>
      <Navbar />
      
      <div className="container mx-auto py-12 px-4 lg:px-0">
      
      {/* Breadcrumbs */}
        <nav className="text-sm text-gray-600 mb-4 max-w-3xl mx-auto" aria-label="Breadcrumb">
          <ul className="flex space-x-2">
            <li>
              <Link to="/" className="hover:text-blue-500">Home</Link>
            </li>
            <span>/</span>
            <li>
              <Link to="/help" className="hover:text-blue-500">Help</Link>
            </li>
            <span>/</span>
            <li aria-current="page" className="text-gray-500">
              {post.title}
            </li>
          </ul>
        </nav>
      
        <h1 className="text-4xl font-medium my-10 text-left text-gray-900 max-w-3xl mx-auto">{post.title}</h1>

        {/* Author, Date, Category, and Reading Time */}
        <div className="md:flex justify-center items-center border-y space-y-2 md:space-y-0 md:space-x-4 py-5 md:py-2 text-gray-600 mb-5 max-w-3xl mx-auto">
          <div className="text-sm">By <span className="font-semibold">{post.author}</span></div>
          <span className="hidden md:block">•</span>
          <div className="text-sm">Published on <span className="font-semibold">{formatDate(post.date)}</span></div>
          <span className="hidden md:block">•</span>
          <div className="text-sm">Category <span className="font-semibold">{post.category}</span></div>
          <span className="hidden md:block">•</span>
          <div className="text-sm"><span className="font-semibold">{readingTime} min</span> read</div>
         <div className="hidden md:block" >
          <LikeButton postId={post.parama} />
          </div>
        </div>

        {/* Markdown Content */}
        <div className="prose prose-lg max-w-3xl mx-auto px-2 md:px-0">
          <ReactMarkdown>{post.content}</ReactMarkdown>
        </div>

        {/* Tags */}
                 <div className="block md:hidden" >
          <LikeButton postId={post.parama} />
          </div>
      </div>
      <Footer />
    </>
  );
}

export default SupportPost;
