import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Helmet } from 'react-helmet-async'; // Correct import
function Blog() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // Search term state
const timestamp = new Date().getTime();
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = date.toLocaleString('en-GB', { month: 'short' });
    const year = date.getFullYear().toString().slice(-2);
    return `${day} ${month}, ${year}`;
  };

  const calculateReadingTime = (content) => {
    const wordsPerMinute = 200;
    const wordCount = content.split(" ").length;
    return Math.ceil(wordCount / wordsPerMinute);
  };

  useEffect(() => {
    fetch(`https://www.pixelliongroup.com/seotic/getBlogs.php?_=${timestamp}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setBlogs(data);
        } else {
          throw new Error("Expected an array but received something else");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching blogs:", error);
        setError(error);
        setLoading(false);
      });
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredBlogs = blogs.filter((post) =>
    post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    post.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    post.category.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <>
        <Navbar />
        <div className="bg-slate-100 h-full">
          <div className="container mx-auto py-12">
            <div className="text-center"><i className="bx bx-circle bx-burst bx-rotate-270 bx-fw"></i>Loading...</div>
          </div>
        </div>
        <Footer />
      </>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <title>Seotic Blog - Powerful SEO Tools for Small Businesses</title>
        <meta
          name="description"
          content="Seotic provides easy to use SEO tools for small businesses to enhance online visibility, drive traffic, and grow your brand. Get started with Seotic today!"
        />
        <meta name="keywords" content="SEO, SEO tools, small business, digital marketing, online visibility, Seotic" />
        <link rel="icon" href="https://www.seotic.co/favicon.ico" />

        {/* Open Graph Meta Tags */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.seotic.co" />
        <meta property="og:title" content="Seotic - SEO Tools for Small Businesses" />
        <meta
          property="og:description"
          content="Optimize your website's SEO with Seotic's powerful, user friendly tools designed for small businesses. Start improving your search rankings today!"
        />
        <meta property="og:image" content="https://www.seotic.co/og-image.jpg" />

        {/* Twitter Card Meta Tags */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.seotic.co" />
        <meta property="twitter:title" content="Seotic - SEO Tools for Small Businesses" />
        <meta
          property="twitter:description"
          content="Optimize your website's SEO with Seotic's powerful, user friendly tools designed for small businesses. Start improving your search rankings today!"
        />
        <meta property="twitter:image" content="https://www.seotic.co/og-image.jpg" />
      </Helmet>
      <Navbar />
      <div className="bg-slate-100 h-full">
        <div className="container mx-auto py-12 px-2 md:px-0">
          <h2 className="text-4xl w-full text-center text-slate-800 mt-12 mb-5 uppercase font-medium">
            SEOBlog
          </h2>
          <p className="text-center text-slate-600 max-w-2xl mx-auto mb-8">
  Discover actionable strategies and in depth guides to help you excel in your digital journey.
</p>

          {/* Search Bar */}
          <div className="mb-12 flex justify-center">
            <input
              type="text"
              placeholder="Search by title, category, or description..."
              value={searchTerm}
              onChange={handleSearch}
              className="px-4 py-2 border border-slate-200 rounded-lg w-full max-w-md focus:outline-none"
            />
          </div>

          <div className="grid gap-8 md:grid-cols-3">
            {filteredBlogs.map((post) => (
              <Link
                key={post.parama}
                to={`/blog/${post.parama}`}
                className="bg-white rounded overflow-hidden border border-slate-200"
              >
                <img className="w-full object-cover" loading="lazy"  src={post.image} alt={post.title} />
                <div className="px-10 py-5">
                  <p className="text-slate-400 text-sm mb-5">
                    {formatDate(post.date)} in {post.category} • {calculateReadingTime(post.content)} min read
                  </p>
                  <h2 className="text-xl font-medium mb-2">{post.title}</h2>
                  <p className="text-slate-500 text-sm mb-4">{post.description}</p>
                </div>
              </Link>
            ))}
          </div>

          {/* No Results Message */}
          {filteredBlogs.length === 0 && (
            <div className="text-center text-slate-500 mt-8">
              No blog posts found for "{searchTerm}"
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blog;
