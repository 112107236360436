import React, { useState, useEffect } from "react";

function InBlog() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Calculate reading time
  const calculateReadingTime = (content) => {
    const wordsPerMinute = 200;
    const wordCount = content.split(" ").length;
    return Math.ceil(wordCount / wordsPerMinute);
  };

  // Format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = date.toLocaleString('en-GB', { month: 'short' });
    const year = date.getFullYear().toString().slice(-2);
    return `${day} ${month}, ${year}`;
  };

  useEffect(() => {
    fetch("https://www.pixelliongroup.com/seotic/getBlogs.php") // Replace with your actual PHP file URL
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setBlogs(data.slice(0, 3)); // Limit to the first 3 blog posts
        } else {
          throw new Error("Expected an array but received something else");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching blogs:", error);
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <>
        <div className="bg-slate-100 h-full">
          <div className="container mx-auto py-12">
            <div className="text-center"><i className='bx bx-circle bx-burst bx-rotate-270 bx-fw'></i>Loading...</div>
          </div>
        </div>
      </>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <div className="bg-slate-100 h-full">
        <div className="container mx-auto py-12">
          <div className="grid gap-8 md:grid-cols-3 mx-2 md:mx-0">
            {blogs.map((post) => (
              <a
                key={post.parama}
                href={`/blog/${post.parama}`} // Link by slug (parama)
                className="bg-white rounded overflow-hidden border border-slate-200"
              >
                <img loading="lazy" className="w-full object-cover" src={post.image} alt={post.title} />
                <div className="px-10 py-5">
                  <p className="text-slate-400 text-sm mb-5">
                    {formatDate(post.date)} in {post.category} • {calculateReadingTime(post.content)} min read
                  </p>
                  <h2 className="text-xl font-medium mb-2">{post.title}</h2>
                  <p className="text-slate-500 text-sm mb-4">{post.description}</p>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default InBlog;
