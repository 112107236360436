import React, { useState } from "react";

function Faq() {
  const [activeFaq, setActiveFaq] = useState(null);

  const toggleFaq = (faqId) => {
    setActiveFaq(activeFaq === faqId ? null : faqId);
  };

  return (
    <section className="py-20">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-medium text-center mb-12">Frequently Asked Questions</h2>

        {/* FAQ Item 1 */}
        <div className="border-b border-gray-200 py-4 md:px-10">
          <button
            onClick={() => toggleFaq("faq1")}
            className="w-full text-left flex justify-between items-center focus:outline-none"
          >
            <span className="text-lg font-medium">What is Seotic, and who is it for?</span>
            <svg
              className={`w-6 h-6 text-gray-500 transition-transform ${
                activeFaq === "faq1" ? "transform rotate-180" : ""
              }`}
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M5 9a1 1 0 011.707-.707L10 11.586l3.293-3.293A1 1 0 0115 9v.002a1 1 0 01-.293.705l-4 4a1 1 0 01-1.414 0l-4-4A1 1 0 015 9z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
          {activeFaq === "faq1" && (
            <div className="mt-2">
              <p className="text-gray-600">
                Seotic is an SEO toolkit for small businesses, marketers, and agencies, offering tools like website
                audits, backlink analysis, and keyword tracking to boost online visibility.
              </p>
            </div>
          )}
        </div>

        {/* FAQ Item 2 */}
        <div className="border-b border-gray-200 py-4 md:px-10">
          <button
            onClick={() => toggleFaq("faq2")}
            className="w-full text-left flex justify-between items-center focus:outline-none"
          >
            <span className="text-lg font-medium">Do I need to manually add all the URLs from my website?</span>
            <svg
              className={`w-6 h-6 text-gray-500 transition-transform ${
                activeFaq === "faq2" ? "transform rotate-180" : ""
              }`}
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M5 9a1 1 0 011.707-.707L10 11.586l3.293-3.293A1 1 0 0115 9v.002a1 1 0 01-.293.705l-4 4a1 1 0 01-1.414 0l-4-4A1 1 0 015 9z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
          {activeFaq === "faq2" && (
            <div className="mt-2">
              <p className="text-gray-600">
                No, Seotic automatically crawls your domains to find all internal URLs. You can then select which ones you’d like to scan.
              </p>
            </div>
          )}
        </div>

        {/* FAQ Item 3 */}
        <div className="border-b border-gray-200 py-4 md:px-10">
          <button
            onClick={() => toggleFaq("faq3")}
            className="w-full text-left flex justify-between items-center focus:outline-none"
          >
            <span className="text-lg font-medium">Do I need technical skills?</span>
            <svg
              className={`w-6 h-6 text-gray-500 transition-transform ${
                activeFaq === "faq3" ? "transform rotate-180" : ""
              }`}
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M5 9a1 1 0 011.707-.707L10 11.586l3.293-3.293A1 1 0 0115 9v.002a1 1 0 01-.293.705l-4 4a1 1 0 01-1.414 0l-4-4A1 1 0 015 9z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
          {activeFaq === "faq3" && (
            <div className="mt-2">
              <p className="text-gray-600">
               Nope! Seotic is easy to use, even for beginners. 
              </p>
            </div>
          )}
        </div>

       
       
    
      </div>
    </section>
  );
}

export default Faq;
