// src/components/LikeButton.js
import React, { useState, useEffect } from "react";

function LikeButton({ postId }) {
  const [likes, setLikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);

  // Fetch initial like count
  useEffect(() => {
    async function fetchLikes() {
      try {
        const response = await fetch(`https://abacus.jasoncameron.dev/hit/seotic.co/${postId}`);
        if (!response.ok) {
          throw new Error("Error fetching like count");
        }
        const data = await response.json();
        setLikes(data.value);
      } catch (error) {
        console.error("Error fetching like count:", error);
      }
    }
    fetchLikes();
  }, [postId]);

  // Handle like button click
  const handleLike = async () => {
    if (isLiked) return; // Prevent multiple likes per user session

    // Optimistically update the like count locally
    setLikes((prevLikes) => prevLikes + 1);
    setIsLiked(true); // Mark as liked instantly for the user

    try {
      const response = await fetch(`https://abacus.jasoncameron.dev/hit/seotic.co/${postId}`);
      if (!response.ok) {
        throw new Error("Error updating like count");
      }
      const data = await response.json();
      setLikes(data.value); // Update with the confirmed count from server
    } catch (error) {
      console.error("Error updating like count:", error);
      // Revert like if there was an error
      setLikes((prevLikes) => prevLikes - 1);
      setIsLiked(false);
    }
  };

  return (
    <button
      onClick={handleLike}
      disabled={isLiked}
      className={`flex items-center px-4 py-2 rounded hover:text-blue-500 ${isLiked ? "text-blue-500" : " text-gray-700"}`}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="mr-2" fill="none">
        <path d="M7.76872 7.99708C9.10954 7.17461 10.2798 7.50606 10.9828 8.03401C11.2711 8.25048 11.4152 8.35871 11.5 8.35871C11.5848 8.35871 11.7289 8.25048 12.0172 8.03401C12.7202 7.50606 13.8905 7.17461 15.2313 7.99708C16.991 9.07647 17.3891 12.6374 13.3302 15.6417C12.5571 16.2139 12.1706 16.5 11.5 16.5C10.8294 16.5 10.4429 16.2139 9.66976 15.6417C5.61086 12.6374 6.00903 9.07647 7.76872 7.99708Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M2 12C2 7.52166 2 5.28249 3.39124 3.89124C4.78249 2.5 7.02166 2.5 11.5 2.5C15.9783 2.5 18.2175 2.5 19.6088 3.89124C21 5.28249 21 7.52166 21 12C21 16.4783 21 18.7175 19.6088 20.1088C18.2175 21.5 15.9783 21.5 11.5 21.5C7.02166 21.5 4.78249 21.5 3.39124 20.1088C2 18.7175 2 16.4783 2 12Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
      </svg>
      {likes}
    </button>
  );
}

export default LikeButton;
