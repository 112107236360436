import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import InBlog from "../components/InBlog";
import { Helmet } from 'react-helmet-async'; // Correct import
function FeaturesPage() {
  return (
	    <>
      {/* Helmet component to wrap all meta tags and title */}
      <Helmet>
        <title>Seotic Features - Powerful SEO Tools for Small Businesses</title>
        <meta
          name="description"
          content="Seotic provides easy to use SEO tools for small businesses to enhance online visibility, drive traffic, and grow your brand. Get started with Seotic today!"
        />
        <meta name="keywords" content="SEO, SEO tools, small business, digital marketing, online visibility, Seotic" />
        <link rel="icon" href="https://www.seotic.co/favicon.ico" />

        {/* Open Graph Meta Tags */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.seotic.co" />
        <meta property="og:title" content="Seotic - SEO Tools for Small Businesses" />
        <meta
          property="og:description"
          content="Optimize your website's SEO with Seotic's powerful, user friendly tools designed for small businesses. Start improving your search rankings today!"
        />
        <meta property="og:image" content="https://www.seotic.co/og-image.jpg" />

        {/* Twitter Card Meta Tags */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.seotic.co" />
        <meta property="twitter:title" content="Seotic - SEO Tools for Small Businesses" />
        <meta
          property="twitter:description"
          content="Optimize your website's SEO with Seotic's powerful, user friendly tools designed for small businesses. Start improving your search rankings today!"
        />
        <meta property="twitter:image" content="https://www.seotic.co/og-image.jpg" />
      </Helmet>
    <div className="">
      <Navbar />
      <Hero />
      
      <section id="features" className="pb-32  bg-gradient-to-t from-amber-50  to-white mt-20">
      <div className="container mx-auto space-y-10">
        
    

        {/* Content Sections */}
 
          <div className="bg-white rounded-lg border border-slate-200 grid grid-cols-1 md:m-0 m-2 md:grid-cols-2 text-left">
            <div className="text-left flex items-center  px-5 py-6 md:p-12 md:px-20">
              <div>
                <h3 className="text-4xl mb-2">Comprehensive Website Audits</h3>
                <p className="text-sm">
                  Our Website Audit tool offers an in-depth look into your site’s SEO health, page quality, and performance.
                  Designed for quick, actionable insights, it’s the essential tool to help you drive more traffic and engagement.
                </p>
                <div className="grid md:grid-cols-2 gap-5 mt-10">
                  <div>
                    <h2 className="text-xl mb-2">SEO Audit</h2>
                    <p className="text-sm">Improve keywords, meta tags, and technical SEO for better visibility.</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">Page Quality Check</h2>
                    <p className="text-sm">Ensure smooth navigation, mobile-friendliness, and clear content on every page.</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">Quick Improvements</h2>
                    <p className="text-sm">Get prioritized recommendations to boost SEO, content, and speed.</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">Core Web Vitals</h2>
                    <p className="text-sm">Check speed, accessibility, and user experience based on Google’s standards.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pl-10 pt-10 hidden md:block">
              <img loading="lazy" className="rounded-tl-xl rounded-br-lg animate-fade animate-once" src="/web-audit.png" alt="SEO Audit" />
            </div>
          </div>
   

     
          <div className="bg-white rounded-lg border border-slate-200 grid grid-cols-1 md:m-0 m-2 md:grid-cols-2 text-left">
           <div className="pr-10 pt-10 hidden md:block">
              <img loading="lazy" className="rounded-tr-xl rounded-bl-lg  animate-fade animate-once" src="backlinks.png" alt="Backlink Analysis" />
            </div>
             <div className="text-left flex items-center  px-5 py-6 md:p-12 md:px-20">
              <div>
                <h3 className="text-4xl mb-2">Backlinks Analysis</h3>
                <p className="text-sm">
                  Our Backlinks feature provides essential insights to help you manage and strengthen your backlink strategy.
                </p>
                <div className="grid md:grid-cols-2 gap-5 mt-10">
                  <div>
                    <h2 className="text-xl mb-2">By Month</h2>
                    <p className="text-sm">Monitor backlink growth month-by-month to understand trends and impact.</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">Top Referring Domains</h2>
                    <p className="text-sm">See which domains drive the most value and authority to your site.</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">Detail List</h2>
                    <p className="text-sm">Access a full list of backlinks with domain details, URLs, and DA.</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">Broken Backlinks</h2>
                    <p className="text-sm">Easily identify and repair broken backlinks to retain link value.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        


              <div className="bg-white rounded-lg border border-slate-200 grid grid-cols-1 md:m-0 m-2 md:grid-cols-2 text-left">
            <div className="text-left flex items-center  px-5 py-6 md:p-12 md:px-20">
              <div>
                <h3 className="text-4xl mb-2">Keywords Research</h3>
                <p className="text-sm">
                  Our Keyword Research tool provides the insights you need to target the right keywords and drive more traffic.
                </p>
                <div className="grid md:grid-cols-2 gap-5 mt-10">
                  <div>
                    <h2 className="text-xl mb-2">Keyword Suggestions</h2>
                    <p className="text-sm">Get a list of keyword ideas tailored to your niche and audience.</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">Monthly Volume</h2>
                    <p className="text-sm">See the monthly search volume to prioritize high-interest keywords.</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">Competitions</h2>
                    <p className="text-sm">Understand how competitive each keyword is to improve targeting.</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">Trend Rate</h2>
                    <p className="text-sm">Monitor changes in search popularity to stay ahead of market shifts.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pl-10 pt-10 hidden md:block">
              <img loading="lazy" className=" rounded-tl-xl rounded-br-lg  animate-fade animate-once" src="/keywords-research.png" alt="Keyword Research" />
            </div>
          </div>
        

 
          <div className="bg-white rounded-lg border border-slate-200 grid grid-cols-1 md:m-0 m-2 md:grid-cols-2 text-left">
            <div className="pr-10 pt-10 hidden md:block">
              <img loading="lazy" className=" rounded-tr-xl rounded-bl-lg animate-fade animate-once" src="/competitors-analysis.png" alt="Competitors Analysis" />
            </div>
             <div className="text-left flex items-center  px-5 py-6 md:p-12 md:px-20">
              <div>
                <h3 className="text-4xl mb-2">Competitor Analysis</h3>
                <p className="text-sm">
                  Our Competitor Analysis tool helps you understand and outpace your competition.
                </p>
                <div className="grid md:grid-cols-2 gap-5 mt-10">
                  <div>
                    <h2 className="text-xl mb-2">Common Keywords</h2>
                    <p className="text-sm">Discover the keywords you and your competitors both target.</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">Gap Keywords</h2>
                    <p className="text-sm">Find keywords your competitors rank for that you’re missing.</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">Estimate Traffic</h2>
                    <p className="text-sm">View estimated traffic to understand your competitors' online visibility.</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">Ranked List</h2>
                    <p className="text-sm">Get a ranked list of competitors based on traffic and keyword performance.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        

      
            <div className="bg-white rounded-lg border border-slate-200 grid grid-cols-1 md:m-0 m-2 md:grid-cols-2 text-left">
            <div className="text-left flex items-center  px-5 py-6 md:p-12 md:px-20">
              <div>
                <h3 className="text-4xl mb-2">Rank Monitor</h3>
                <p className="text-sm">
                  Our Rank Monitor tool helps you stay updated on keyword rankings and make data-driven improvements.
                </p>
                <div className="grid md:grid-cols-2 gap-5 mt-10">
                  <div>
                    <h2 className="text-xl mb-2">Rank History</h2>
                    <p className="text-sm">View historical ranking data to measure growth and performance trends.</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">SERP by Keyword</h2>
                    <p className="text-sm">Check where your keywords stand in search engine results pages (SERPs).</p>
                  </div>
                  <div>
                    <h2 className="text-xl mb-2">Ranked URL</h2>
                    <p className="text-sm">Discover which URLs are ranking for each keyword to optimize and refine content.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pl-10 pt-10 hidden md:block">
              <img loading="lazy"  className="rounded-tl-xl rounded-br-lg animate-fade animate-once" src="/rank-monitor.png" alt="Rank Monitor" />
            </div>
          </div>
        
      </div>
      
    </section>



      <InBlog />
      <Faq />
      <Footer />
    </div>
    </>
  );
}

export default FeaturesPage;
