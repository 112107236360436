import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

function Navbar() {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false); // State to toggle mobile menu

  // Helper function to determine if a link is active
  const isActive = (path) =>
    location.pathname === path ? "text-yellow-500 font-semibold" : "hover:text-yellow-500";

  return (
    <nav className="relative flex justify-between items-center px-5 md:px-0 py-6 z-10 mx-auto container text-sm font-medium">
      <div className="w-28">
        <Link to="/">
          <img src="/seotic-logo.png" alt="Seotic Logo" />
        </Link>
      </div>

      {/* Desktop Navigation */}
      <div className="space-x-10 hidden md:flex uppercase items-center">
        <Link to="/" className={isActive("/")}>Home</Link>
        <a href="/features" className={isActive("/features")}>Features</a>
        <Link to="/blog" className={isActive("/blog")}>Blog</Link>
        <a href="/pricing" className={isActive("/pricing")}>Pricing</a>
        <a className="hover:text-yellow-500 flex items-center">
          Academy <span className="text-xs w-fit bg-green-500 text-white rounded px-3 py-1 ml-2">soon</span>
        </a>
        <a href="/help" title="support" className={isActive("/help")}>Support</a>
      </div>

      <div className="space-x-10 hidden md:flex uppercase items-center">
        <a
          href="https://app.seotic.co/login"
          target="_blank"
          title="login"
          className="text-right hover:text-blue-600"
        >
          Login
        </a>
        <a
          href="https://app.seotic.co/register"
          target="_blank"
          title="register"
          className="px-5 py-2 bg-yellow-300 text-yellow-900 rounded-lg hover:bg-yellow-400 hidden md:block"
        >
          Free Register
        </a>
      </div>

      {/* Hamburger Icon for Mobile */}
      <div className="md:hidden">
        <button onClick={() => setMenuOpen(!menuOpen)} className="text-gray-700 focus:outline-none">
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
      </div>

      {/* Mobile Menu Overlay */}
      {menuOpen && (
        <div className="fixed inset-0 bg-black  z-20">
          <div className="flex flex-col items-center justify-center h-full space-y-8 text-white uppercase text-lg font-medium">
            <Link to="/" className={isActive("/")} onClick={() => setMenuOpen(false)}>Home</Link>
            <a href="/features" className={isActive("/features")} onClick={() => setMenuOpen(false)}>Features</a>
            <Link to="/blog" className={isActive("/blog")} onClick={() => setMenuOpen(false)}>Blog</Link>
            <a href="/pricing" className={isActive("/pricing")} onClick={() => setMenuOpen(false)}>Pricing</a>
            <a className="hover:text-yellow-500 flex items-center" onClick={() => setMenuOpen(false)}>
              Academy <span className="text-xs w-fit bg-green-500 text-white rounded px-3 py-1 ml-2">soon</span>
            </a>
            <a href="/help" title="support" className={isActive("/help")} onClick={() => setMenuOpen(false)}>Support</a>
            <a href="https://app.seotic.co/login" target="_blank" title="login" className="hover:text-blue-600" onClick={() => setMenuOpen(false)}>Login</a>
            <a href="https://app.seotic.co/register" target="_blank" title="register" className="px-5 py-2 bg-yellow-300 text-yellow-900 rounded-lg hover:bg-yellow-400" onClick={() => setMenuOpen(false)}>Free Register</a>
          </div>
          {/* Close button for mobile menu */}
          <button className="absolute top-5 right-5 text-white" onClick={() => setMenuOpen(false)}>
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
